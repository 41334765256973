@font-face{
font-family: 'Roboto';
src: local('Roboto'), local('Roboto-Regular'), url('./fonts/Roboto/Roboto-Regular.ttf') format('truetype');
font-weight: normal;
font-style: normal;
}

@font-face{
font-family: 'Roboto';
src: local('Roboto Bold'), local('Roboto-Bold'), url('./fonts/Roboto/Roboto-Bold.ttf') format('truetype');
font-weight: bold;
font-style: normal;
}

@font-face{
font-family: 'Roboto';
src: local('Roboto Italic'), local('Roboto-Italic'), url('./fonts/Roboto/Roboto-Italic.ttf') format('truetype');
font-weight: normal;
font-style: italic;
}
@font-face{
font-family: 'Roboto';
src: local('Roboto BoldItalic'), local('Roboto-BoldItalic'), url('./fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
font-weight: bold;
font-style: italic;
}
@font-face{
font-family: 'Roboto';
src: local('Roboto Light'), local('Roboto-Light'), url('./fonts/Roboto/Roboto-Light.ttf') format('truetype');
font-weight: 300;
font-style: normal;
}
@font-face{
font-family: 'Roboto';
src: local('Roboto LightItalic'), local('Roboto-LightItalic'), url('./fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
font-weight: 300;
font-style: italic;
}
@font-face{
font-family: 'Roboto';
src: local('Roboto Medium'), local('Roboto-Medium'), url('./fonts/Roboto/Roboto-Medium.ttf') format('truetype');
font-weight: 500;
font-style: normal;
}
@font-face{
font-family: 'Roboto';
src: local('Roboto MediumItalic'), local('Roboto-MediumItalic'), url('./fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
font-weight: 500;
font-style: italic;
}
@primary-color: #1890FF;@card-radius: 12px;